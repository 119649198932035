import { createTheme } from "@mui/material/styles";
import {
  Primary,
  // PrimaryLight,
  // PrimaryDark,
  PrimaryContrast,
  // PrimaryBG,
  // GreyDark,
  // Grey,
  // Error,
  // Success,
  // Warning,
  BaseFontSize,
  MobileBaseFontSize,
} from "./constants";

const theme = ({ base_colour, isMobile }) => {
  const responsiveBaseFontSize = isMobile ? MobileBaseFontSize : BaseFontSize;
  return createTheme({
    palette: {
      primary: {
        main: base_colour || Primary,
        contrastText: PrimaryContrast,
      },
      white: {
        main: "#ffffff",
      },
      light: {
        main: "#f5f5f5",
      },
      black: {
        main: "#000000",
        contrastText: "#ffffff",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },

    typography: {
      fontSize: responsiveBaseFontSize,
      fontFamily: ["Montserrat"].join(","),
      button: {
        textTransform: "none",
      },

      h1: {
        fontSize: responsiveBaseFontSize * 3,
        fontWeight: 600,
      },
      h2: {
        fontSize: responsiveBaseFontSize * 2.5,
        fontWeight: 600,
      },
      h3: {
        fontSize: responsiveBaseFontSize * 2,
        fontWeight: 600,
      },
      h4: {
        fontSize: responsiveBaseFontSize * 1.5,
        fontWeight: 600,
      },
      h5: {
        fontSize: responsiveBaseFontSize * 1.25,
        fontWeight: 600,
      },
      h6: {
        fontSize: responsiveBaseFontSize,
        fontWeight: 600,
      },
      title: {
        fontSize: responsiveBaseFontSize * 2.5,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: responsiveBaseFontSize,
      },
      body2: {
        fontSize: responsiveBaseFontSize * 0.875,
      },
      finePrint: {
        fontSize: responsiveBaseFontSize * 0.7,
      },
      subtitle1: {
        fontSize: responsiveBaseFontSize * 2,
        lineHeight: 1.5,
      },
      subtitle2: {
        fontSize: responsiveBaseFontSize * 1.5,
        lineHeight: 1.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          disableRipple: true,
          disableFocusRipple: true,
        },
        styleOverrides: {
          sizeLarge: {
            fontSize: "1rem",
          },
          sizeMedium: {
            fontSize: "0.875rem",
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true,
          color: "primary",
        },
      },
      MuiRadio: {
        defaultProps: { disableRipple: true, color: "primary" },
      },
      MuiSwitch: {
        defaultProps: { color: "primary", disableRipple: true },
      },
      MuiTab: {
        defaultProps: { disableRipple: true },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            border: "none",
            padding: "8px",
            backgroundColor: "#F5F5F5",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: "none",
            borderRadius: "8px !important",
            "&.MuiToggleButton-primary": {
              color: Primary,
              "&.Mui-selected": {
                backgroundColor: Primary,
                color: "#ffffff",
              },
            },
          },
        },
      },
    },
  });
};

export default theme;
